@function rem($number) {
    @return ($number / 100) + rem;
}

* {
    margin: 0;padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "微软雅黑";
}
img {
    display: block;
}
#top-container {
    max-width: 750px;
    margin: 0 auto rem(100);
    // border: 1px solid blue;
    overflow: hidden;
}


// 公共头部
#common-header {
    height: rem(174);
    .logo-phone {
        height: rem(105);
        overflow: hidden;
        &>.logo {
            float: left;
            height: 100%;
        }
        &>.contact-phone {
            float: right;
        }
        img {
            height: 100%;
        }
    }
    .navbar {
        height: rem(70);
        width: 100%;
        background: #eee;
        padding-left: rem(34);
        white-space: nowrap;
        overflow: hidden;
        overflow-x: auto;
    }
    .navbar-list {
        font-size: 0;
        a {
            display: inline-block;
            float: none; 
            font-size: rem(24);
            line-height: rem(75);
            padding: 0 rem(20);
            color: #595757;
        }
        .cur,.a {
            color: #fc5465;
        }
    }
}
// 公共头部结束
// 导航与banner分割线
.partition-line {
    max-height: 3px;
    height: rem(3);
    min-height: 1px;
    background: #fc5465;
}

// 首页轮播banner
.banner-container {
    .swiper-wrapper {
        width: 100%;
        height: rem(445);
        position: relative;
        .swiper-slide img {
            width: 100%;
            height: 100%;
        }
    }
    .swiper-pagination {
        text-align: center;
        font-size: 0;
        overflow: hidden;
        span {
            width: rem(16);
            height: rem(16);
            background: #fed9dd;
            display: inline-block;
            border-radius: 50%;
            margin: rem(14) rem(7) 0;
        }
        span.cur {
            background: #fc5465;
        }
    }
}
// 首页轮播banner结束
// 固定单张banner
.single-banner {
    width: 100%;
    max-width: rem(750);
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
    }
}
// 固定单张banner结束

// 标题
.title-container {
    height: rem(87);
    background-image: url('../images/index/title.png');
    background-size: cover;
}

// 标题结束

// 按钮
.big-btn {
    width: rem(48);
    height: rem(88);
    display: block;
    position: absolute;
    background: url('../images/index/btn.png') no-repeat center;
    background-size: rem(133) rem(143);
    top: rem(120);
    z-index: 100;
    outline: none;
}
.big-left-btn {
    left: 0;
    background-position: 0 0;
}
.big-right-btn {
    left: rem(488);
    background-position: rem(-84) 0;
}

.small-btn {
    font-size: 0;
    display: inline-block;
    width: rem(43);
    height: rem(43);
    background: url('../images/index/btn.png') no-repeat;
    background-size: rem(133) rem(143);
    background-position-y: rem(-103);
}
.small-right-btn {
    background-position: rem(-87) rem(-103);
}
// 按钮结束

// 客户问题
.title-big-size {
    height: rem(97);
    margin-top: rem(58);
    background-position-y: rem(-39);
}
.customer-problem {
    margin-top: rem(15);
}
.customer-problem img {
    width: 100%;
}
// 客户问题结束

// 我们擅长的业务
.title-yewu {
    margin: rem(40) auto;
    background-position-y: rem(-180);
}
.yewu {
    .yewu-container {
        .page-box {
            font-size: 0;
            text-align: center;
            padding: rem(20) 0;
            .cur {
                color: #fff;
                background-color: #fd6d7b;
            }
        }
        .page-item {
            display: inline-block;
            width: rem(210);
            height: rem(63);
            color: #fc5465;
            font-size: rem(32);
            text-align: center;
            line-height: rem(63);
            box-shadow: 2px 2px rem(8) 1px rgba(190,61,75,0.5);
            border-radius: rem(15);
            margin:0 rem(20);
            outline: none;
        }
        .yewu-wrapper {
            position: relative;
            height: rem(460);
            .bg {
                width: 100%;
                height: rem(220);
                background-color: #fc5465;
                position: absolute;
                bottom: 0;
            }
            .swiper-wrapper {
                width: rem(686);
                height: rem(372);
                margin: rem(5) auto 0;
                .swiper-slide {
                    background: url('../images/index/yewu_slide_bg.png') no-repeat;
                    background-size: contain;
                    padding: rem(36) 0 0 rem(25);
                    .description {
                        width: rem(440);
                        height: rem(246);
                        overflow: hidden;
                        font-size: rem(24);
                        line-height: rem(41);
                        color: #595857;
                        text-indent: 2.5em;
                    }
                    .know-more {
                        display: block;
                        width: rem(180);
                        height: rem(46);
                        background: url('../images/index/more-button.png') no-repeat center;
                        background-size: contain;
                        font-size: rem(18);
                        color: #fff;
                        line-height: rem(46);
                        text-indent: 2em;
                        margin-top: rem(20);
                    }
                }
            }
        }
    }
}
// 我们擅长的业务结束
// 公司介绍 了解破镜重圆
.introduction {
    .title-container {
        margin: rem(75) auto rem(45);
        background-position-y: rem(-340);
    }
    .introduction-show {
        width: 100%;
        height: rem(689);
    }
}
// 公司介绍 了解破镜重圆结束
// 专家团队
.tutor {
    overflow: hidden;
    .title-container {
        background-position-y: rem(-500);
    }
    .tutor-container {
        position: relative;
    }
    .swiper-container {
        padding: rem(20) rem(10);
        width: rem(712);
        float: right;
    }
    .swiper-wrapper {
        width: rem(425);
        height: rem(326);
    }
    .swiper-wrapper .swiper-slide {
        
        opacity: 0.6;
        box-shadow: 1px 2px rem(5) rgba(140,35,46, 0.3);
        padding: rem(12);

        img {
            width: 100%;
            max-height: rem(268);
            margin: 0 auto;
        }
    }
    .swiper-wrapper .swiper-slide-active {
        opacity: 1;
    }
    .control-btn {
        position: relative;
    }
    .swiper-pagination {
        font-size: 0;
        float: left;
        margin-left: rem(90);
        span {
            display: inline-block;
            background: #fedde0;
            width: rem(15);
            height: rem(15);
            border-radius: 50%;
            margin: rem(10) rem(5);
            outline: none;
        }
        .cur {
            background: #fc5465;
        }
    }
}
// 专家团队结束
// 导师团队
.tutor-group {
    background: url('../images/index/tutor-group-bg.png') no-repeat center;
    background-size: contain;
    height: rem(760);
    margin-top: rem(50);
    overflow: hidden;
    .title-container {
        background-position-y: rem(-630);
        margin: rem(66) auto rem(60)
    }
    .swiper-wrapper {
        width: rem(684);
        height: rem(400);
        margin: 0 auto;
        .swiper-slide>div {
            position: absolute;
        }
        .tutor-info {
            left: rem(225);
            top: rem(10);
            z-index: 99;
            .name {
                font-size: rem(38);
                color: #fc5465;
            }
            .t {
                font-size: rem(24);
                color: #656565;
            }
        }
        .tutor-description {
            width: rem(542);
            height: rem(280);
            background: url('../images/index/tutor-item-bg.png') no-repeat center;
            background-size: contain;
            bottom: rem(17);
            right: rem(90);
            z-index: 97;
            color: #fff;
            font-size: rem(24);
            padding:rem(18) rem(18) 0 rem(160);
            line-height: rem(40);
        }
        .tutor-avatar {
            width: rem(239);
            height: rem(380);
            border-bottom: rem(3) solid #fc5566;
            z-index: 98;
            position: absolute;
            bottom: rem(17);
        }
    }
    .swiper-container {
        .control {
            font-size: 0;
            float: right;
            margin-right: rem(52);
            margin-top: rem(10);
            .btn {
                font-size: 0;
                display: inline-block;
            }
        }
        .swiper-pagination {
            display: inline-block;
            height: rem(43);
            padding: rem(13) rem(15);
            font-size: 0;
            span {
                display: block;
                width: rem(15);
                height: rem(15);
                border-radius: 50%;
                background: #ebcbd2;
                margin: 0 rem(6);
                float: left;
            }
            .cur {
                background: #fc5465;
            }
        }
    }
}
// 导师团队结束
// 媒体报道的报纸
.media-newspaper {
    background: url('../images/index/media-bg.png') no-repeat center;
    background-size: cover;
    height: rem(761);
    .title-container {
        position: relative;
        top: rem(-30);
        background-position-y: rem(-785);
    }
    .newspaper-container {
        width: rem(681);
        padding: 0 rem(10);
        margin: 0 auto;
        .media-logo {
            width: rem(176);
            float: left;
            div {
                width: rem(160);
                height: rem(72);
                border-radius: rem(15);
                margin-top: rem(14);
                border: rem(2) solid #fff;
                background: #fff;
                outline: none;
                span {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: url('../images/index/media-logo.png') no-repeat center;
                    background-size: rem(138) rem(551);
                    background-position: rem(8) rem(8);
                }
            }
            div:nth-child(2) span {
                background-position-y: rem(-75);
            }
            div:nth-child(3) span {
                background-position-y: rem(-155);
            }
            div:nth-child(4) span {
                background-position-y: rem(-235);
            }
            div:nth-child(5) span {
                background-position-y: rem(-323);
            }
            div:nth-child(6) span {
                background-position-y: rem(-405);
            }
            div:nth-child(7) span {
                background-position-y: rem(-492);
            }
            div.cur {
                border: rem(2) solid #ff8692;
                box-shadow: rem(2) rem(2) rem(8) rgba(210,40,57, 0.52);
            }
        }
        .newspaper-list {
            width: rem(483);
            height: rem(620);
            padding: rem(15);
            overflow: hidden;
            float: left;
            .swiper-wrapper {
                // width: rem(459);
                // height: rem(610);
                img {
                    width: 99%;
                    height: 99%;
                    box-shadow: 1px 1px rem(12) rgba($color: #000000, $alpha: 0.4);
                }
            }
        }
    }
}
// 媒体报道的报纸结束
// 客户反馈
.customer-feedback {
    overflow: hidden;
    padding-bottom: rem(80);
    .title-container {
        background-position-y: rem(-918);
        margin: rem(75) auto rem(44);
    }
    .feedback-wrapper {
        position: relative;
        .big-btn {
            top: rem(230);
        }
        .big-left-btn {
            left: rem(20);
        }
        .big-right-btn {
            left: rem(380);
        }
        .swiper-pagination {
            font-size: 0;
            margin-left: rem(22);
            margin-top: rem(-35);
            width: rem(400);
            text-align: center;
            span {
                width: rem(16);
                height: rem(16);
                background: #fed9dd;
                display: inline-block;
                border-radius: 50%;
                margin: rem(14) rem(7) 0;
            }
            span.cur {
                background: #fc5465;
            }
        }
    }
    .swiper-container {
        width: rem(613);
        margin: 0 auto;
        .swiper-wrapper {
            width: rem(324);
            height: rem(700);
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .mask {
            width: rem(306);
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9;
            background: rgba($color: #fff, $alpha: .7);
        }
    }
}
// 客户反馈结束
// 挽回爱情资料
.affection-data {
    .affection-wrapper {
        width: rem(704);
        margin: 0 auto;  
        padding: 0 rem(10) 
    }
    
    .title-container {
        background-position-y: rem(-1080);
    }
    .pagination {
        font-size: 0;
        padding: rem(10) 0;
        span {
            display: inline-block;
            font-size: rem(28);
            color: #fc5566;
            background: white;
            box-shadow: rem(2) rem(2) rem(6) rem(1) rgba($color: #92731d, $alpha: 0.4);
            padding: rem(10) rem(24);
            border-radius: rem(10);
            margin-right: rem(18);
            outline: none;
        }
        span.cur {
            color: #fff;
            background: #fc5465;
        }
    }
    .swiper-wrapper {
        width: 100%;
        margin-top: rem(35);
        .swiper-slide {
            div {
                overflow: hidden;
                margin-bottom: rem(30);
            }
            span {
                display: block;
                float: left;
                width: rem(10);
                height: rem(25);
                background: #fc5566;
                margin-top: rem(6);
            }
            a {
                display: block;
                width: 100%;
                float: left;
                color: #3e3a39;
                font-size: rem(28);
                margin-left: rem(-25);
                padding: 0 rem(35);
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
            }
        }
    }
}
// 挽回爱情资料结束

// 底部
.footer {
    background: #fc5566;
    text-align: center;
    padding: rem(40) 0;
    p {
        margin: rem(8) 0;
    }
    a,p {
        color: #fff;
        font-size: rem(20);
    }
}
// 底部结束
// 固定底部导航
.footer-nav {
    width: 100%;
    max-width: rem(750);
    height: rem(100);
    background: url('../images/footer/footer-nav-bg.png') no-repeat;
    background-size: contain;
    position: fixed;
    bottom: 0;
    z-index: 99999;
    a {
        display: block;
        width: 33.3333%;
        height: rem(46);
        float: left;
        margin-top: rem(27);
        color: #fff;
        font-size: rem(24);
        padding-left: rem(40);
        i {
            display: block;
            float: left;
            width: rem(54);
            height: rem(47);
            background: url('../images/footer/nav-icon.png') no-repeat;
            background-size: rem(162) rem(46);
            background-position-x: rem(5);
        }
        span {
            display: block;
            float: left;
            line-height: rem(48);
            padding-left: rem(10);
        }
    }
    a:nth-child(2) i {
        background-position-x: rem(-54);
    }
    a:nth-child(3) i {
        background-position-x: rem(-108);
    }
    a:nth-child(1)::after,a:nth-child(2)::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: #d9d9d9;
        float: right;
    }
}
.qrcode-wrapper {
    width: 100%;
    max-width: rem(750);
    height: 100%;
    position: fixed;
    top: 0;
    margin: 0 auto;
    background: rgba($color: #000, $alpha: 0.7);
    display: none;
    img {
        display: block;
        width: rem(270);
        margin: rem(50) auto;
    }
    p {
        color: #fff;
        font-size: rem(25);
        .wechatid {
            color: red;
        }
        max-width: rem(600);
        margin: 0 auto;
    }
    .qt {
        text-align: center;
    }

}
// 固定底部导航结束

// 页面位置面包屑导航
.page-position {
    padding: rem(35) 0;
    overflow: hidden;
    .home-icon {
        width: rem(26);
        background: url('../images/list/home-icon.png') no-repeat;
        background-size: contain;
    }
    a,span {
        height: rem(26);
        display: block;
        font-size: rem(20);
        color: #a6a6a6;
        float: left;
        margin-right: rem(5);
    }
}
// 页面位置面包屑导航结束
// 列表页
// 栏目导航
.category-nav {
    height: rem(77);
    background: #fc5465;
    overflow: hidden;
    .swiper-container {
        float: left;
        width: rem(668);
        height: 100%;
        padding-left: rem(34);
        a {
            display: block;
            font-size: rem(25);
            line-height: rem(77);
            padding: 0 rem(20);
            color: #fd818c;
        }
        .cur a {
            color: #fff;
        }
    }
    .control-btn {
        float: left;
        width: rem(80);
        height: 100%;
        background: url('../images/list/right-btn.png') no-repeat rem(10) rem(22);
        background-size: 20.8% 37%;
        outline: none;
    }
}
// 栏目导航结束
.category-main {
    width: rem(684);
    margin: 0 auto;
    .category-description {
        color: #7d7d7d;
        font-size: rem(22);
        padding-bottom: rem(35);
        text-indent: 2em;
        line-height: rem(35);
    }
    .list {
        width: rem(636);
        margin: 0 auto;
        .item {
            padding: rem(16) 0 rem(22);
            border-bottom: 1px dashed #fc5465;
            overflow: hidden;
            img {
                width: rem(197);
                height: rem(135);
                border-radius: rem(10);
                display: block;
                float: left;
            }
            .article-info {
                float: right;
                width: rem(410);
                .article-title {
                    font-size: rem(23);
                    color: #595757;
                    font-weight: 400;
                    max-height: rem(58);
                    margin-top: rem(-4);
                }
            }
            .digest {
                margin: rem(14) 0;
                height: rem(35);
            }
            .digest,.other-info span {
                font-size: rem(15);
                color: #898989;
                float: left;
            }
            .other-info span:first-child {
                width: 50%;
            }
            .other-info span:last-child {
                float: right;
            }
        }
    }
    .page {
        text-align: center;
        font-size: 0;
        margin: rem(60) auto rem(20);
        a,span {
            display: inline-block;
            font-size: rem(22);
            color: #b2b2b2;
            margin: 0 rem(6);
            border: 1px solid #b2b2b2;
            padding: rem(12) rem(22);
            border-radius: rem(8);
        }
        .current {
            color: #fff;
            background: #fc5566;
            border-color: #fc5566;
        }
    }
}
// 列表页结束

// 内容页
.article-main {
    padding: 0 rem(35);
    .title {
        font-size: rem(44);
        line-height: rem(73);
        color: #222;
    }
    .section-name {
        font-size: rem(31);
        color: #fc5465;
    }
    .article-info {
        padding-top: rem(35);
        font-size: 0;
        overflow: hidden;
        span {
            display: block;
            font-size: rem(22);
            float: left;
            color: #a6a6a6;
        }
        span:first-child {
            width: 36%;
        }
        span:last-child {
            float: right;
        }
    }
    .introduction {
        font-size: 0;
        padding: rem(35) 0 rem(53);
        overflow: hidden;
        i {
            display: block;
            width: rem(56);
            height: rem(36);
            background: url('../images/article/introduction-icon.png') no-repeat;
            background-size: contain;
            float: left;
        }
        &>span {
            float: left;
            padding-left: rem(10);
        }
        span {
            font-size: rem(25);
            color: #3e3a39;
        }
        span.mark {
            color: #fc5465;
        }
    }
    .article-content {
        p,span,i,string,b,div,font {
            font-size: rem(29);
            color: #333;
            line-height: rem(57);
        }
        p {
            margin: rem(15) auto;
        }
        img {
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .correlation-article-list {
        p {
            line-height: rem(90);
        }
        .list-wrapper {
            padding: 0 rem(40);
            font-size: 0;
            overflow: hidden;
            li {
                margin-bottom: rem(30);
            }
        }
        a {
            display: block;
            font-size: rem(25);
            color: #fc5465;
            text-decoration: underline;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .guidance {
        padding: rem(70) rem(20);
        overflow: hidden;
        .figure {
            width: rem(115);
            height: rem(115);
            position: relative;
            overflow: hidden;
            border-radius: rem(8);
            border: 1px solid #ff8692;
            box-shadow: rem(5) rem(5) rem(12) rgba($color: #d22839, $alpha: .4);
            float: left;
            img {
                width: 100%;
                height: 100%;
            }
            p {
                width: 100%;
                height: rem(28);
                background: #fc5465;
                color: #fff;
                font-size: rem(14);
                line-height: rem(28);
                text-align: center;
                position: absolute;
                bottom: 0;
            }
        }
        .bubble {
            width: rem(515);
            height: rem(84);
            float: right;
            background: url('../images/article/guidance-bubble.png') no-repeat;
            background-size: contain;
            padding: rem(8) rem(40);
            p {
                font-size: rem(19);
                color: #fff;
                margin-top: rem(5);
                .wc-code {
                    color: #7d0000;
                    text-decoration: underline;
                    padding: 0 rem(10);
                }
                .copy-btn {
                    font-size: rem(17);
                    color: #fc5465;
                    border-radius: rem(30);
                    background: #fff;
                    padding: rem(5) rem(15);
                }
            }
        }
    }
    .adjoin-article {
        p {
            font-size: rem(25);
            color: #808080;
            margin-bottom: rem(30);
            span {
                float: left;
                display: block;
            }
            a {
                display: block;
                float: left;
                width: rem(460);
                color: #808080;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        p::before,p::after {
            content: '';
            display: block;
            clear: both;
        }
    }
    .tutor-recommend {
        &>p {
            margin: rem(70) 0 rem(25) 0;
        }
        .tutor-wrapper {
            padding: 0 rem(22);
            overflow: hidden;
            .item {
                width: rem(307);
                height: rem(165);
                box-shadow: rem(5) rem(5) rem(15) rgba($color: #d22839, $alpha: 0.35);
                float: left;
                margin: rem(20) 0;
                border-radius: rem(10);
                img {
                    display: block;
                    float: left;
                    position: relative;
                    width: rem(106);
                    height: rem(174);
                    margin-left: rem(20);
                    bottom: rem(8);
                }
            }
            .item:nth-child(even) {
                float: right;
            }
        }
        .tutor-info {
            width: rem(170);
            float: right;
            .tutor-name {
                color: #fc5465;
                font-size: rem(25);
                margin: rem(15) auto rem(7);
            }
            .intro {
                color: #535353;
                font-size: rem(18);
            }
            .consulting-btn {
                width: rem(127);
                background: #fe707f;
                font-size: rem(19);
                color: #fff;
                padding: rem(5) rem(24);
                border-radius: rem(15);
                margin-top: rem(8);
            }
        }
        
    }
    .recommend-category {
        &>p {
            margin:rem(50) auto rem(20);
        }
        .category-list {
            font-size: 0;
            overflow: hidden;
            padding: rem(20) 0;
            a {
                display: block;
                float: left;
                font-size: rem(25);
                color: #fc5566;
                background: #fff;
                line-height: rem(44);
                padding: 0 rem(36);
                box-shadow: rem(3) rem(3) rem(6) rgba($color: #92731d, $alpha: 0.4);
                border-radius: rem(10);
                margin: 0 rem(20) rem(20) 0;
            }
            a.cur {
                background: #fd7482;
                color: #fff;
            }
        }
    }
    .latest-information {
        margin: rem(30) auto;
        ul li {
            margin-top: rem(20);
            overflow: hidden;
            font-size: 0;
            em {
                font-size: rem(25);
                color: #434343;
                float: left;
                margin-right: rem(20);
            }
            a {
                color: #3e3a39;
                font-size: 0;
                span {
                    display: block;
                    font-size: rem(25);
                    float: left;
                }
                span:first-child {
                    max-width: rem(450);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                span:last-child {
                    float: right;
                }
            }
        }
        li:nth-child(1) em,li:nth-child(2) em,li:nth-child(3) em {
            color: #fc5465;
        }
    }
    .article-tag {
        padding-bottom: rem(20);
        &>p {
            margin: rem(55) auto rem(35);
        }
        .tags-list {
            font-size: 0;
            a {
                display: inline-block;
                font-size: rem(25);
                color: #fc5465;
                border: 1px solid #fc5465;
                border-radius: rem(10);
                padding: rem(8) rem(18);
                margin: 0 rem(20) rem(20) 0;
            }
        }
    }
}
// 内容页结束